import '../App.css';

function Carregando(props) {
  return (
    <div style={
        {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            paddingTop: '5%'
        }
    }>
        <img src='6b5d0327-5b27-4e8a-919e-d3a50ab3ac62.jpg' style={{width:'60%'}}/>
        <h1>Carregando Sorteio</h1>        
    </div>
  );
}

export default Carregando;
