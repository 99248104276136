import './App.css';
import MelhoresCartelas from './components/melhores-cartelas';
import PainelNumeros from './components/painel-numeros';
import Bolas from './components/bolas';
import Premios from './components/premios';
import InfoSorteio from './components/info-sorteio';
import axios from 'axios'
import { useEffect, useState } from 'react';
import moment from 'moment'
import NextPrizeDraws from './components/aguardando/next-prize-draws';
import NextPrizeDraw from './components/aguardando/next-prize-draw';
import Sorteio from './components/sorteio';
import {socket} from './socket.js';
import Carregando from './components/carregando.jsx';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// const baseUrl = 'http://138.197.28.215:5000';
const NADA = 0;
const AGUARDANDO = 1;
const SORTEIO = 2;
const CARREGANDO = 3;
const SAIUGANHADOR = 4;
const GANHADORES = 5;
const FECHADO = 6;

Audio.prototype.play = (function(play) {
  console.log('1')
  return function () {
    console.log('2')
    var audio = this,
        args = arguments,
        promise = play.apply(audio, args);
    if (promise !== undefined) {
      console.log('3')
      promise.catch(_ => {
        // Autoplay was prevented. This is optional, but add a button to start playing.
        var el = document.createElement("button");
        el.innerHTML = "Play";
        el.addEventListener("click", function(){play.apply(audio, args);});
        this.insertBefore(el, this.nextSibling)
        console.log('4')
      });
    }
  };
})(Audio.prototype.play);

const audios = {
  audio1: new Audio('./audio/audio-001.mp3'),
  audio2: new Audio('./audio/audio-002.mp3'),
  audio3: new Audio('./audio/audio-003.mp3'),
  audio4: new Audio('./audio/audio-004.mp3'),
  audio5: new Audio('./audio/audio-005.mp3'),
  audio6: new Audio('./audio/audio-006.mp3'),
  audio7: new Audio('./audio/audio-007.mp3'),
  audio8: new Audio('./audio/audio-008.mp3'),
  audio9: new Audio('./audio/audio-009.mp3'),
  audio10: new Audio('./audio/audio-010.mp3'),
  audio11: new Audio('./audio/audio-011.mp3'),
  audio12: new Audio('./audio/audio-012.mp3'),
  audio13: new Audio('./audio/audio-013.mp3'),
  audio14: new Audio('./audio/audio-014.mp3'),
  audio15: new Audio('./audio/audio-015.mp3'),
  audio16: new Audio('./audio/audio-016.mp3'),
  audio17: new Audio('./audio/audio-017.mp3'),
  audio18: new Audio('./audio/audio-018.mp3'),
  audio19: new Audio('./audio/audio-019.mp3'),
  audio20: new Audio('./audio/audio-020.mp3'),
  audio21: new Audio('./audio/audio-021.mp3'),
  audio22: new Audio('./audio/audio-022.mp3'),
  audio23: new Audio('./audio/audio-023.mp3'),
  audio24: new Audio('./audio/audio-024.mp3'),
  audio25: new Audio('./audio/audio-025.mp3'),
  audio26: new Audio('./audio/audio-026.mp3'),
  audio27: new Audio('./audio/audio-027.mp3'),
  audio28: new Audio('./audio/audio-028.mp3'),
  audio29: new Audio('./audio/audio-029.mp3'),
  audio30: new Audio('./audio/audio-030.mp3'),
  audio31: new Audio('./audio/audio-031.mp3'),
  audio32: new Audio('./audio/audio-032.mp3'),
  audio33: new Audio('./audio/audio-033.mp3'),
  audio34: new Audio('./audio/audio-034.mp3'),
  audio35: new Audio('./audio/audio-035.mp3'),
  audio36: new Audio('./audio/audio-036.mp3'),
  audio37: new Audio('./audio/audio-037.mp3'),
  audio38: new Audio('./audio/audio-038.mp3'),
  audio39: new Audio('./audio/audio-039.mp3'),
  audio40: new Audio('./audio/audio-040.mp3'),
  audio41: new Audio('./audio/audio-041.mp3'),
  audio42: new Audio('./audio/audio-042.mp3'),
  audio43: new Audio('./audio/audio-043.mp3'),
  audio44: new Audio('./audio/audio-044.mp3'),
  audio45: new Audio('./audio/audio-045.mp3'),
  audio46: new Audio('./audio/audio-046.mp3'),
  audio47: new Audio('./audio/audio-047.mp3'),
  audio48: new Audio('./audio/audio-048.mp3'),
  audio49: new Audio('./audio/audio-049.mp3'),
  audio50: new Audio('./audio/audio-050.mp3'),
  audio51: new Audio('./audio/audio-051.mp3'),
  audio52: new Audio('./audio/audio-052.mp3'),
  audio53: new Audio('./audio/audio-053.mp3'),
  audio54: new Audio('./audio/audio-054.mp3'),
  audio55: new Audio('./audio/audio-055.mp3'),
  audio56: new Audio('./audio/audio-056.mp3'),
  audio57: new Audio('./audio/audio-057.mp3'),
  audio58: new Audio('./audio/audio-058.mp3'),
  audio59: new Audio('./audio/audio-059.mp3'),
  audio60: new Audio('./audio/audio-060.mp3'),
  audio61: new Audio('./audio/audio-061.mp3'),
  audio62: new Audio('./audio/audio-062.mp3'),
  audio63: new Audio('./audio/audio-063.mp3'),
  audio64: new Audio('./audio/audio-064.mp3'),
  audio65: new Audio('./audio/audio-065.mp3'),
  audio66: new Audio('./audio/audio-066.mp3'),
  audio67: new Audio('./audio/audio-067.mp3'),
  audio68: new Audio('./audio/audio-068.mp3'),
  audio69: new Audio('./audio/audio-069.mp3'),
  audio70: new Audio('./audio/audio-070.mp3'),
  audio71: new Audio('./audio/audio-071.mp3'),
  audio72: new Audio('./audio/audio-072.mp3'),
  audio73: new Audio('./audio/audio-073.mp3'),
  audio74: new Audio('./audio/audio-074.mp3'),
  audio75: new Audio('./audio/audio-075.mp3'),
  audio76: new Audio('./audio/audio-076.mp3'),
  audio77: new Audio('./audio/audio-077.mp3'),
  audio78: new Audio('./audio/audio-078.mp3'),
  audio79: new Audio('./audio/audio-079.mp3'),
  audio80: new Audio('./audio/audio-080.mp3'),
  audio81: new Audio('./audio/audio-081.mp3'),
  audio82: new Audio('./audio/audio-082.mp3'),
  audio83: new Audio('./audio/audio-083.mp3'),
  audio84: new Audio('./audio/audio-084.mp3'),
  audio85: new Audio('./audio/audio-085.mp3'),
  audio86: new Audio('./audio/audio-086.mp3'),
  audio87: new Audio('./audio/audio-087.mp3'),
  audio88: new Audio('./audio/audio-088.mp3'),
  audio89: new Audio('./audio/audio-089.mp3'),
  audio90: new Audio('./audio/audio-090.mp3'),
  saiuganhador: new Audio('./audio/saiuganhador.mp3'),
  parabens: new Audio('./audio/premiados.mp3'),
}

function App() {
  const [user, setUser] = useState(undefined);
  const [prizedraws, setPrizedraws] = useState([]);
  const [prizedraw, setPrizedraw] = useState(undefined);
  const [status, setStatus] = useState(FECHADO)
  const [winners, setWinners] = useState([]);
  const [ganhadores, setGanhadores] = useState(undefined)
  const [acumulado, setAcumulado] = useState(undefined)

  useEffect(() => {
    // login()
    function onConnect() {
      // setIsConnected(true)
      // console.log(`connected`);
    }

    function onDisconnect() {
      // setIsConnected(false);
      // console.log(`disconnected`);
    }

    function onFooEvent(value) {
      // console.log(status);
      // if (status == FECHADO) return;
      // console.log('ué')
      if (value.aguardando) {
        setStatus(AGUARDANDO)
        setPrizedraws(value.proximos)
        setAcumulado(value.acumulado);
        return
      }
      if (value.carregando) {
        setStatus(CARREGANDO)
        return
      }
      if (value.sorteio) {
        setStatus(SORTEIO)
        console.log(value)
        setPrizedraw({
          ...value.prizedraw,
          accumulated: value.accumulated,
          numbers: value.numeros,
          winners: value.sorteados,
          bestbets: value.bestbets
        })
        return
      }
      if (value.winners) {
        setStatus(SAIUGANHADOR)
        setWinners(value.winners)
        audios.saiuganhador.play();
        return
      }

      if (value.ganhadores) {
        setStatus(GANHADORES)
        setGanhadores(value.ganhadores)
        audios.parabens.play();
      }
      
    }
    function onMessageEvent(value) {
      // setFooEvents(previous => [...previous, value]);
      // console.log(value);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onFooEvent);
    socket.on('message', onMessageEvent);
    
  }, [])

  if (status == FECHADO) {
    return  <div className="App">
      <button onClick={() => {
        setStatus(NADA);
        socket.connect();
      }
      }>Clique aqui para Iniciar Transmissão</button></div>
  }
  if (status == CARREGANDO) {
    return <Carregando />
  }
  
  return (
<div className="App">
    {status == AGUARDANDO ?
      <>
        <Container fluid>
          <Row>
            <Col xs={5}><NextPrizeDraw info={prizedraws[0]} acumulado={acumulado}/></Col>
            <Col xs={3}><NextPrizeDraws info={prizedraws[1]}/></Col>
            <Col xs={3}><NextPrizeDraws info={prizedraws[2]}/></Col>
          </Row>
        </Container>
      
      
      </>
      
      :
      <><main class="flex-shrink-0 main">
        
      <div id="app-conferencia"> 
        <div class="principal"> 

        {status == GANHADORES ?
          <>
          <div className="premiado">
            <div className="janela">
              <div className="box">
                GANHADORES
              </div>
              <Row>
                <Col>
                <div className='cartela'>
                <h1>1 Prêmio</h1>
                {ganhadores.firstAward.map((item) => (
                  <div className='doador'>
                    {item.doador} <br></br> {item.cardNumber} - R${item.valor.toFixed(2)}
                  </div>
                  
                  )
                )
                }
              </div>
              </Col>  
              <Col>
              <div className='cartela'>
                <h1>2 Prêmio</h1>
                {ganhadores.secondAward.map((item) => (
                  <div className='doador'>
                    {item.doador} <br></br> {item.cardNumber} - R${item.valor.toFixed(2)}
                  </div>
                  )
                )
                }
              </div></Col>   

              <Col>
              <div className='cartela'>
                <h1>3 Prêmio</h1>
                {ganhadores.thirdAward.map((item) => (
                  <div className='doador'>
                    {item.doador} <br></br>  {item.cardNumber} - R${item.valor.toFixed(2)}
                  </div>
                  )
                )
                }
              </div>
              </Col>       
              </Row>

              
              
            </div>
          </div>
          
          
          </>
          
          :
          <></>
          }



        {status == SAIUGANHADOR ?
          <>
          <div className="premiado">
            <div className="janela">
              <div className="box">
                SAIU GANHADOR
              </div>
              {/* <div className='cartela'> */}
              {/* <div class="premiado-cartelas"> */}
                    {/* <div class="premiado-cartelas"> */}
                      <Row>
                      
                {winners.map((item) => {
                return   <Col xs={5}><div class="cartela">
                      <div class="doador">{item.doador}</div> 
                      {/* prizedraw */}
                      {item.numbers.map(numero => {
                        return <div className={`${prizedraw.numbers.includes(numero) ? "numero sorteado" : "numero"}`}>{numero}</div>  
                      })}
                      <div class="rodape">
                        <div class="cupom">{item.cardNumber}</div> 
                        <div class="valor">R$&nbsp;{item.valor.toFixed(2)}</div>
                      </div>
                    </div>
                
                </Col>
                    }
                )}
                </Row>
                {/* </div> */}
                {/* </div> */}
                
              {/* </div> */}
              
            </div>
          </div>
          
          
          </>
          
          :
          <></>
          }







          


          {status == SORTEIO && prizedraw ?
          <Container fluid>
            <Sorteio prizedraw={prizedraw} audios={audios}/>
          </Container>
          
          : <></> }
        
    </div>
  </div> 
  </main></>
      }
    
      
      
    </div>
  );
}

export default App;
