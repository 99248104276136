import moment from 'moment'
import Timer from './timer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NextPrizeDraw(props) {
    // console.log(props.info.dateTime)
    return (
        <>
        <Row className="caixinha-item">
            <Col xs={12}>
            <div className="caixinha">
                <div className="label">PRÓXIMO SORTEIO EM</div> 
                <div className="valor cronometro"><Timer dataAlvo={props.info.dateTime}/></div>
            </div> 
            </Col>
        </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha">
                    <div className="label">RODADA</div> 
                    <div className="valor">{props.info.code}</div>
                </div> 
            </Col>
        </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha">
                    <div className="label">DOAÇÃO</div> 
                    <div className="valor">R${props.info.value.toFixed(2)}</div>
                </div> 
            </Col>
        </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha">
                    <div className="label">DATA HORA</div> 
                    <div className="valor">{moment(new Date(props.info.dateTime)).utc().format('DD/MM/YY')} {moment(new Date(props.info.dateTime)).utc().format('HH:mm')}</div>
                </div> 
            </Col>
        </Row>
        <Row className="caixinha-item">
            <Col xs>
                <div className="caixinha">
                    <div className="label">1 PRÊMIO</div> 
                    <div className="valor">{props.info.firstAward.toFixed(2)}</div>
                </div> 
            </Col>
            <Col xs>
                <div className="caixinha">
                    <div className="label">2 PRÊMIO</div> 
                    <div className="valor">{props.info.secondAward.toFixed(2)}</div>
                </div> 
            </Col>
            <Col xs>
                <div className="caixinha">
                    <div className="label">3 PRÊMIO</div> 
                    <div className="valor">{props.info.thirdAward.toFixed(2)}</div>
                </div> 
            </Col>
        </Row>
        <Row className="caixinha-item blink">
            <Col xs={12}>
                <div className="caixinha" style={{backgroundColor: "red"}}>
                    <div className="label" style={{backgroundColor: "red"}}>ACUMULADO
                    <span class="acumulado-numero-espera">
                        <span>{props.acumulado ? props.acumulado.quantity : ''}</span>
                    </span></div> 
                    <div className="valor">{props.acumulado ? props.acumulado.value.toFixed(2) : ''}</div>
                    
                </div> 
            </Col>
            
        </Row>
        </>
    // <div className="info margins">
        // <div className="box margins-cronometro">
        //     <span className="label">PRÓXIMO SORTEIO EM</span> 
        //     <span className="caixadestaque value data_sorteio"><b><Timer dataAlvo={props.info.dateTime}/></b></span>
        // </div> 
    //     <div className="box  margins">
    //         <span className="label">RODADA</span> 
    //         <span className="caixadestaque value sorteio_id">{props.info.code}</span>
    //     </div>
    //     <div className="box  margins">
    //         <span className="label">DOAÇÃO</span> 
    //         <span className="caixadestaque value valor_sorteio">R${props.info.value.toFixed(2)}</span>
    //     </div> 
    //     <div className="box  margins">
    //         <span className="label">DATA HORA</span> 
    //         <span className="caixadestaque value data_sorteio">{moment(new Date(props.info.dateTime)).utc().format('DD/MM/YY')} {moment(new Date(props.info.dateTime)).utc().format('HH:mm')}</span>
    //     </div> 
    //     <div className="box  boxinline">
    //     {/* <table> */}
    //         {/* <tr> */}
    //             {/* <td> */}
    //                 <div className="box margins">
    //                     <span className="label">1 PRÊMIO</span> 
    //                     <span className="caixadestaque value data_sorteio">{props.info.firstAward.toFixed(2)}</span>
    //                 </div>
    //             {/* </td> */}
    //             {/* <td> */}
    //                 <div className="box margins">
    //                     <span className="label">2 PRÊMIO</span> 
    //                     <span className="caixadestaque value data_sorteio">{props.info.secondAward.toFixed(2)}</span>
    //                 </div>
    //             {/* </td> */}
    //             {/* <td> */}
    //                 <div className="box margins ">
    //                     <span className="label">3 PRÊMIO</span> 
    //                     <span className="caixadestaque value data_sorteio">{props.info.thirdAward.toFixed(2)}</span>
    //                 </div>
    //             {/* </td> */}
    //         {/* </tr> */}
    //     {/* </table> */}
    //     </div>
        
    //     <div className="box  margins blink">
    //         <span className="titulocaixa">ACUMULADO</span> 
    //         <span className="caixadestaque value data_sorteio"><b>{props.acumulado ? props.acumulado.value.toFixed(2) : ''}</b></span>
    //         <div class="acumulado-numero-espera">
    //             <span>{props.acumulado ? props.acumulado.quantity : ''}</span>
    //         </div>
    //     </div> 
        
        
        
    // </div> 
    
    
    );
  }
  
  export default NextPrizeDraw;
  
  