import Acumulado from "./acumulado";

function InfoSorteio(props) {
    return (
        
<div class="info">
    <div class="box">
        <span class="label">SORTEIO</span> 
        <span class="value sorteio_id">{props.info.sorteio}</span>
    </div>
    <div class="box">
        <span class="label">DOAÇÃO</span> 
        <span class="value valor_sorteio">R${props.info.doacao ? props.info.doacao.toFixed(2) : 0}</span>
    </div> 
    <div class="box">
        <span class="label">DATA</span> 
        <span class="value data_sorteio">{props.info.data}</span>
    </div> 
    <div class="box">
        <span class="label">HORA</span> 
        <span class="value hora_sorteio">{props.info.hora}</span>
    </div>
    </div> 
    );
  }
  
  export default InfoSorteio;
  
  