function Acumulado(props) {
    return (
        <div className={`premio acumulado ${props.numbers && props.numbers.length < props.valor.quantity? 'acumulado-ativo' : ''} `}>
            <div class="value">
                R${props.valor ? props.valor.value.toFixed(2) : 0}
            </div> 
            {/* <div class="star">
            <img src={"images/premio-acumulado-star.png"}/>
            </div>  */}
            <div class="acumulado-numero">
            <span>{props.valor ? props.valor.quantity : 0}</span>
            </div>
        </div> 
    );
  }
  
  export default Acumulado;
  