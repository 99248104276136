import { useEffect, useState } from "react";

function PainelNumeros(props) {
    // console.log(props)
    const [numeros, setNumeros] = useState(Array.from({length: 90}, (e, i)=> i+1))

    return (
        <div class="painel-numeros">
            {numeros.map((item) => {
                if (props.numerosSorteados && props.numerosSorteados.includes(item) && props.ordem < props.numerosSorteados.length){
                    return <div class="numero sorteado">{item}</div>         
                }
                
                return <div class="numero">{item}</div>
            }
            )}
        </div> 
    );
  }
  
  export default PainelNumeros;
  