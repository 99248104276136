function Bola(props) {

    const getColor = () => {
        if (props.numero <= 30){
            return 'green';
        }
        if (props.numero <= 60){
            return 'blue';
        }
        if (props.numero <= 90){
            return 'red';
        }
    }

    return (
        <div className="bolasorteio" style={{backgroundColor: getColor()}}>
            <div className="bolacentro">
                <p className={`bolanumero${props.ordem}`}>{props.numero.toString().padStart(2, '0')}</p>
            </div>
        </div>
    )

}
export default Bola;