import moment from 'moment'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NextPrizeDraws(props) {
    if (!props.info){
        return <></>
    }
    return (
        <>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">RODADA</div> 
                    <div className="valor">{props.info.code}</div>
                </div> 
            </Col>
            </Row>
            <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">DOAÇÃO</div> 
                    <div className="valor">R${props.info.value.toFixed(2)}</div>
                </div> 
            </Col>
            </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">DATA</div> 
                    <div className="valor">{moment(new Date(props.info.dateTime)).utc().format('DD/MM/YY')}</div>
                </div> 
            </Col>
            </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">HORA</div> 
                    <div className="valor">{moment(new Date(props.info.dateTime)).utc().format('HH:mm')}</div>
                </div> 
            </Col>
            </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">1 PRÊMIO</div> 
                    <div className="valor">{props.info.firstAward.toFixed(2)}</div>
                </div> 
            </Col>
            </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">2 PRÊMIO</div> 
                    <div className="valor">{props.info.secondAward.toFixed(2)}</div>
                </div> 
            </Col>
            </Row>
        <Row className="caixinha-item">
            <Col xs={12}>
                <div className="caixinha-simples">
                    <div className="label">3 PRÊMIO</div> 
                    <div className="valor">{props.info.thirdAward.toFixed(2)}</div>
                </div> 
            </Col>
        </Row>
        </>
    // <div className="info margins">
    //     <div className="box  margins">
    //         <span className="label">RODADA</span> 
    //         <span className="value sorteio_id">{props.info.code}</span>
    //     </div>
    //     <div className="box  margins">
    //         <span className="label">DOAÇÃO</span> 
    //         <span className="value valor_sorteio">R${props.info.value.toFixed(2)}</span>
    //     </div> 
    //     <div className="box  margins">
    //         <span className="label">DATA</span> 
    //         <span className="value data_sorteio">{moment(new Date(props.info.dateTime)).utc().format('DD/MM/YY')}</span>
    //     </div> 
    //     <div className="box margins">
    //         <span className="label">HORA</span> 
    //         <span className="value data_sorteio">{moment(new Date(props.info.dateTime)).utc().format('HH:mm')}</span>
    //     </div>
    //     <div className="box margins">
    //         <span className="label">1 PRÊMIO</span> 
    //         <span className="value data_sorteio">{props.info.firstAward.toFixed(2)}</span>
    //     </div>
    //     <div className="box margins">
    //         <span className="label">2 PRÊMIO</span> 
    //         <span className="value data_sorteio">{props.info.secondAward.toFixed(2)}</span>
    //     </div>
    //     <div className="box margins">
    //         <span className="label">3 PRÊMIO</span> 
    //         <span className="value data_sorteio">{props.info.thirdAward.toFixed(2)}</span>
    //     </div>
    // </div> 
    
    );
  }
  
  export default NextPrizeDraws;
  
  