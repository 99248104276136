import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate)
  countDownDate.setHours(countDownDate.getHours() + 3);
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };

// import React from 'react';
// import { useCountdown } from './hooks/useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <>
      <div>
      {minutes.toString().padStart(2,'0')} : {seconds.toString().padStart(2,'0')}
      </div>
      {/* <div className="espera"> */}
        {/* <div className="tela-espera"> */}
          {/* <div className="relogio"> */}
            {/* <div className="numero">{minutes.toString().padStart(2,'0')}</div>  */}
            {/* <div className="doispontos">:</div>  */}
            {/* <div className="numero">{seconds.toString().padStart(2,'0')}</div>  */}
          {/* </div> */}
        {/* </div> */}
      {/* </div> */}
      </>
    );
  };

const Timer = ({ dataAlvo }) => {
  const [days, hours, minutes, seconds] = useCountdown(dataAlvo);

    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  
};
export default Timer;
