import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
function MelhoresCartelas(props) {
  const faltam = (item) => {
    const retorno = [];
    for (let i = 0; i < 5; i++){
      if (item.falta[i]){
        
        retorno.push(<span class="">{item.falta[i]}</span>);
      } else {
        retorno.push(<span class="nulo"></span>);
      }

    }
    return retorno;
  }
  return (
    <div class="lista-melhores-cartelas">

      




      <div class="topo">
        <div class="cupom">CUPOM</div> 
          <div class="doador">DOADOR</div> 
          <div class="faltam">FALTAM</div>
          <div class="button">-</div>
        </div> 
        <div class="corpo">
          {props.melhores ?
          props.melhores.map((item, index) => {
              if (index >11) return <></>
            return (
              
            <div class="linha">
              <div class="cupom">
                <div class="bg-branco">
                  {item.ganhador.cupom.toString().padStart(3, '0')}
                </div>
              </div> 
              <div class="doador">
                <div class="bg-branco">
                  {item.ganhador.doador}
                </div>
              </div> 
              <div class="faltam"><div class="bg-branco">
                {faltam(item.ganhador)}
              </div>
            </div>
            </div>
            );
          })
          : <></>
          }
        
      </div>
      <div className='bilhete-melhores-cartelas'>
        <Container fluid style={{margin: 0, padding:0}}>
        <Row style={{margin: 0, padding:0}}>
          {props.melhores ?
          props.melhores.map((item, index) => {
            if (index > 3) return <></>
            return (
              
              <Col xl={5}>
                
                  <div className='cartela' style={{margin: 0, padding:0}}>
                      <div class="doador">{item.ganhador.doador}</div> 
                      {/* prizedraw */}
                      {item.numeros.map(numero => {
                        return <div className={`${props.numeros.includes(numero) ? "numero sorteado" : "numero"}`}>{numero}</div>  
                      })}
                      <div class="rodape">
                        <div class="cupom">CUPOM {item.ganhador.cupom}</div> 
                      </div>
                  </div>
                    
              </Col>);
            }):<></>}
        </Row>
        </Container>
      </div>
    </div>
  );
}

export default MelhoresCartelas;
