import Acumulado from "./acumulado";

function Premios(props) {
  console.log('premios', props)
    return (
        <div class="premios">
        <Acumulado numbers={props.numbers} valor={props.premios.accumulated}/>
        <div class={`premio premio-1 ${!props.winners || props.winners.length == 0 ? 'premio-ativo' : ''}`}>
          <div class="label">PRÊMIO 1</div> 
          <div class="value">R${props.premios.premio1}</div>
        </div> 
        <div class={`premio premio-2 ${!props.winners ||props.winners.length <= 1 ? 'premio-ativo' : ''}`}>
          <div class="label">PRÊMIO 2</div> 
          <div class="value">R${props.premios.premio2}</div>
        </div> 
        <div class={`premio premio-3 ${!props.winners || props.winners.length <= 2 ? 'premio-ativo' : ''}`}>
          <div class="label">PRÊMIO 3</div> 
          <div class="value">R${props.premios.premio3}</div>
        </div>
      </div> 
    );
  }
  
  export default Premios;
  