import '../App.css';
import '../sorteio.css';
import MelhoresCartelas from './melhores-cartelas';
import PainelNumeros from './painel-numeros';
import Bolas from './bolas';
import Premios from './premios';
import InfoSorteio from './info-sorteio';
// import axios from 'axios'
import { useEffect, useState } from 'react';
import moment from 'moment'
// import NextPrizeDraws from './aguardando/next-prize-draws';
// import NextPrizeDraw from './aguardando/next-prize-draw';

function Sorteio(props) {
  // const [prizedraw, setPrizedraw] = useState(undefined);
  const [ordem, setOrdem] = useState(0)
  
  if (props.prizedraw.numbers) {
    props.audios['audio'+props.prizedraw.numbers[props.prizedraw.numbers.length-1]].play();
  }
  if (props.prizedraw) {
  return (
      
      <main class="flex-shrink-0 main">
        
        <div id="app-conferencia"> 
          <div class="principal"> 
            <>
              <div class="esquerda">
                <Premios winners={props.prizedraw.winners}
                  numbers={props.prizedraw.numbers}
                  premios={{
                    premio1: props.prizedraw.firstAward,
                    premio2: props.prizedraw.secondAward,
                    premio3: props.prizedraw.thirdAward,
                    accumulated: props.prizedraw.accumulated
                  }} />
                <div class="centro sorteio-bolas">
                  <div class="sorteio-bolas">
                    <InfoSorteio info={{
                          sorteio: props.prizedraw.code,
                          doacao: props.prizedraw.value,
                          data: moment(props.prizedraw.dateTime).utc().format('DD/MM/YY'),
                          hora: moment(props.prizedraw.dateTime).utc().format('HH:mm'),
                    }}/> 
                    <Bolas numbers={props.prizedraw.numbers}/>
                  </div> 
                </div> 
              <PainelNumeros numerosSorteados={props.prizedraw.numbers} ordem={ordem}/>
              
            </div>
            <div class="coluna-direita compra-fechada">
              <MelhoresCartelas numeros={props.prizedraw.numbers} melhores={props.prizedraw.bestbets}/>
            </div>
            </>
          
      </div>
    </div> 
    </main>
  );
    } else {
        return <></>;
    }
}

export default Sorteio;
