import Bola from './bola';

function Bolas(props) {
  if (!props.numbers){
    return <></>
  }
  return (
    <>
      <div class="bolas">
        <div class="bola bola-1">
          {props.numbers.length ?
          <Bola numero={props.numbers[props.numbers.length-1]} ordem={1}/>
          : <></>}
        </div>
        <div class="bola bola-2">
        {props.numbers.length > 1 ?
          <Bola numero={props.numbers[props.numbers.length-2]} ordem={2}/>
          : <></>}
        </div>
        <div class="bola bola-3">
        {props.numbers.length > 2 ?
          <Bola numero={props.numbers[props.numbers.length-3]} ordem={3}/>
          : <></>}
        </div>
        <div class="bola bola-4">
          {props.numbers.length > 3 ?
          <Bola numero={props.numbers[props.numbers.length-4]} ordem={4}/> 
          : <></>}
        </div>
      </div> 
      <div class="ordem">
      ORDEM&nbsp;
        <span class="ordem_sorteio">{props.numbers.length}</span>
      </div>
    </>
    );
  }
  
  export default Bolas;
  